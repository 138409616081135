import { defineStore } from 'pinia'
import { BannerBlog } from '~/services/articles/models/Banner'

export const useBlogStore = defineStore({
  id: 'blog',

  state: () => ({
    articles: [],
    articlesLoading: false,
    listSearch: [],
    bannerBlogSide: {},
  }),

  getters: {
    getAllArticle: (state) => state.articles,
    getArticlesSearch: (state) => state.listSearch,
    getBannerBlogSide: (state) => state.bannerBlogSide,
  },

  actions: {
    setArticles(payload: any) {
      this.articles = payload
    },

    setArticlesLoading(payload: boolean) {
      this.articlesLoading = payload
    },

    setSearchArticles(payload: any) {
      this.listSearch = payload
    },

    setBannerSide(payload: BannerBlog) {
      this.bannerBlogSide = payload
    },
  },
})
